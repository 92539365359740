import React, { useState } from 'react';
import axios from 'axios';
import './ContactUs.css'
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

export const ContactUsLeftSide = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    brief: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://crm.tealslab.com/api/customer', {
        ...formData,
        news: 1, 
        route: window.location.pathname, 
        brand: 'authorsbreeze', 
        tag: 'coupon pop form', 
        price: 'null', 
        domain: 'www.ecommonks.com' 
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data); 
      
      setFormData({
        name: '',
        email: '',
        phone: '',
        brief: ''
      });
       // Redirect to thank you page after successful form submission
       navigate('/thank-you'); // Adjust the route to your thank you page

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
    <section class="breadcrumbs_style">
       <div class="h-100 container">
                        <div class="h-100 row">
                            <div class="col-12">
                                <ul class="breadcrumb contacts">
                                    <li>
                                        <Link to='/'> Home</Link>
                                    </li>
                                    <li>
                                    <HiOutlineArrowNarrowRight/>
                                    </li>
                                    <li>
                                        <Link to='/portfolio'> Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
      <h2 className="main-heading">Contact Us</h2>
      <p className="main-para">Feel free to talk to us, write to us, or finish the contact form at the bottom of this page.<br/>
        {/* Call (Toll-Free): <Link to="tel:+16508786634"> (650) 878-6634</Link> <br/> Email: <Link to="mailto:query@authorsbreeze.com">query@authorsbreeze.com</Link> */}
      </p>
      <div className="form-box-main clearfix">
        <h2>We would like to hear from you</h2>
        <form className="form_submission telsubmit" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <label className="field-txt">Name <span>*</span></label>
              <input type="text" className="form-control" placeholder="Enter Your Name *" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <label className="field-txt">Email <span>*</span></label>
              <input type="email" className="form-control" placeholder="Enter email here *" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="col-md-12">
              <label className="field-txt">Phone Number<span>*</span></label>
              <input type="tel" className="form-control number phone-country" minLength="10" maxLength="12" placeholder="Phone Number *" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="col-md-12">
              <label className="field-txt">Additional Comments <span>*</span></label>
              <textarea className="form-control" placeholder="Enter message here" name="brief" value={formData.brief} onChange={handleChange} required></textarea>
            </div>
            <div className="col-md-12">
              <input type="submit" value="Submit" />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
